import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import PopupImage from "../images/static-only/breast-is-best-popup.jpg"
import Accordion from "../components/Accordion"

/* Accordion Listing structure = Hero > Accordions */

class AccordionListing extends React.Component {
  componentDidMount() {
    Accordion()
    document.body.classList.remove('-nav-active')
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Meet MJN - Our Nutrition Pledge</title>
        </Helmet>
        <main role="main" className="content-main -content-accordion-listing">

          <section className="hero-area -general -accordion-listing">
            <img src={PopupImage} alt="Mother breast feeding her baby" className="modal-inner__image" />            

            <div className="hero-area__inner">
              <h1>Our <strong>Nutrition Pledge</strong></h1>
              <nav className="breadcrumb" role="navigation">
                  <Link to="/home" title="Home">
                    <i className="fas fa-arrow-left"></i>
                    Home
                  </Link>
              </nav>
            </div>
          </section>

          <section className="accordion-listing">
            <nav className="breadcrumb -accordion -white" role="navigation">
              <Link to="/home" title="Home">
                <i className="fas fa-arrow-left"></i>
                Home
              </Link>
            </nav>

            <div className="accordion">
              <div className="accordion__heading menu-button">Infant &amp; Child Nutrition Pledge</div>
              <div className="accordion__content">
                <div className="rich-text">
                  
                  <p>RB's purpose is to deliver innovative solutions for healthier lives and happier homes. We aim to support consumers across all of life's stages, especially during the first 1000 days.</p>
                  <p>The acquisition of Mead Johnson Nutrition (MJN) in June 2017, means that RB is now one of the key players in the Breast-Milk Substitutes (BMS) industry. Acting responsibly is core to our purpose. With our entrance into the BMS category, we commit to providing the highest quality infant and nutritional products, and to market these responsibly and ethically. We will respect not only our own high standards, but also act according to a set of recommendations on the marketing of BMS, as issued in 1981 by the World Health Organisation commonly referred to as the 'WHO Code'.</p>
                  <p>The aim of the WHO Code is to contribute to the provision of safe and adequate nutrition for infants, by protecting and promoting breastfeeding, and by ensuring the proper use of BMS, when these are necessary.</p>
                  <p>On 30 April 2018, RB introduced its first BMS Marketing Policy, an important milestone and a firm illustration of our commitment to acknowledging the importance of the principles of the WHO Code and subsequent relevant World Health Assembly (WHA) resolutions as implemented by governments. Whilst we recognise there are differing views within the industry, we look forward to the opportunity to engage with key stakeholders and other interested parties to help initiate positive change in the industry as a whole, and to contribute to healthier lives and happier homes.</p>
                  <p>RB commits to be at the forefront of partnerships with stakeholders to meet the UN Sustainable Development Goals (SDGs) and we will work across industry, government and civil society to promote engagement, transparency and continuous improvement. We recognise that growth and responsibility go hand-in-hand. Financially, socially, and environmentally, we act responsibly and sustainably.</p>

                  <p><em>Important Notice: Breastfeeding is best for babies. The decision to discontinue breastfeeding may be difficult to reverse and the introduction of partial bottle-feeding may reduce breast milk supply. The financial benefits of breastfeeding should be considered before bottle-feeding is initiated. Failure to follow preparation instructions carefully may be harmful to your baby's health.</em></p>
                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">Scope</div>
              <div className="accordion__content">
                <div className="rich-text">
                  
                  <p>Our Pledge and our BMS Marketing Policy, applies to all in the RB Group. Our commitments will be applied consistently wherever we operate and adherence is mandatory for all RB employees and third parties acting under RB's authorisation.</p>
                  <p><strong>We acknowledge the importance of the principles of the WHO Code and subsequent relevant WHA resolutions as implemented by governments.</strong></p>
                  <p>Globally we commit to respecting all local laws and legislation implementing the WHO Code. This applies:</p>
                  <ol>
                    <li>To all Infant Formulas, and</li>
                    <li>To all Delivery Products.</li>
                  </ol>
                  <p>In Higher-Risk countries, as defined by FTSE4Good, our BMS Marketing Policy will cover the following in-scope products:</p>
                  <ol>
                    <li>Infant Formula, Delivery Products, and</li>
                    <li>Follow-on Formulas, and</li>
                    <li>Complementary Foods and Beverages for Infants under six months of age.</li>
                    <p>Further, in Higher-Risk countries, our BMS Marketing Policy applies to all forms of Marketing and related materials.</p>
                    <p>In all Higher-Risk countries, we will respect whichever are the stricter requirements relating to BMS Marketing - be that local legislation and regulations implementing the WHO Code or our own BMS Marketing Policy. This commitment applies unequivocally to both product scope and/or recommended age period of marketing restrictions.</p>
                  </ol>

                  <p><em>Important Notice: Breastfeeding is best for babies. The decision to discontinue breastfeeding may be difficult to reverse and the introduction of partial bottle-feeding may reduce breast milk supply. The financial benefits of breastfeeding should be considered before bottle-feeding is initiated. Failure to follow preparation instructions carefully may be harmful to your baby's health.</em></p>
                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">Our Pledge</div>
              <div className="accordion__content">
                <div className="rich-text">
                  
                  <ol>
                    <li><strong>We commit to help prevent malnourishment and undernutrition</strong>, estimated to be associated with 2.7 million child deaths annually. In-line with the WHO Global Targets 2025, we believe that quality infant and child nutrition is key to improving child survival and promoting healthy growth and development, as well as reducing levels of stunting.</li>
                    <li><strong>We support the WHO Code recommendation for exclusive breastfeeding in the first six months of life, and encourage continued breastfeeding for up to two years and beyond.</strong> We support the introduction of safe and appropriate Complementary Foods from six months of age. We commit to actively support breastfeeding for all families and we will work across our supply chain, with our partners, employees and consumers to promote the best start in life and optimal nutrition for the first 1000 days.</li>
                    <li><strong>Globally, we commit to respecting all legislation implementing the WHO Code.</strong> In all Higher-Risk countries, we respect whichever are the stricter requirements relating to BMS Marketing - be that local legislation or our own BMS Marketing Policy, which applies to all Infant Formulas, Follow-On Formulas, Delivery Products and Complementary Foods for Infants under six months of age.</li>
                    <li><strong>We commit to improve women's empowerment in the communities where we live and work, and to diversity across our organisation.</strong> We have a particular focus on improving the lives of mothers and infants.</li>
                    <li><strong>We commit to improve health, access to clean water and sanitation,</strong> because these are the foundations of good health and nutrition.</li>
                    <li><strong>We commit to continuous improvement and transparency.</strong> Our Pledge will evolve as we seek guidance from key stakeholders, conduct internal and external verifications, and changes in the external environment. The CEO has responsibility for this Pledge and we will ensure all updates will be public and that we engage meaningfully with stakeholders to demonstrate progress.</li>
                    <li><strong>We commit as a key player in science-based infant and child nutrition,</strong> to continue our scientific and medical research to provide the highest quality infant nutrition, so that children can achieve their full potential.</li>
                  </ol>

                  <p><em>Important Notice: Breastfeeding is best for babies. The decision to discontinue breastfeeding may be difficult to reverse and the introduction of partial bottle-feeding may reduce breast milk supply. The financial benefits of breastfeeding should be considered before bottle-feeding is initiated. Failure to follow preparation instructions carefully may be harmful to your baby's health.</em></p>
                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">RB's commitment to breastfeeding</div>
              <div className="accordion__content">
                <div className="rich-text">
                  <p>Mead Johnson Nutrition is a division of Reckitt Benckiser (RB), a British multinational consumer goods company.</p>
                  
                  <p>RB believes breast-milk provides the best nutrition for infants to achieve optimal growth and development.</p>
                  <p>RB is unequivocal in its commitment to creating supportive environments for breastfeeding and encourages all women should they choose, to breastfeed their infant in the first six months of life.</p>
                  <p>We will not promote Infant Formula in a way that encourages women to choose it over breastfeeding. RB will help children survive and thrive by providing the best nutritional start in life, whichever feeding method parents choose. We believe that there is an important role for RB in providing innovative and science-based nutrition solutions that promote healthy diets for mothers and children.</p>
                  <p>We support the WHO recommendation for exclusive breastfeeding in the first six months, followed by the introduction of nutritious Complementary Foods thereafter. We also support continued breastfeeding up to two years of age and beyond. We will communicate in a responsible and ethical way at all times, so as to create clarity – not confusion – for mothers who are making informed choices about their children's nutrition. We will work with others in our industry, customers, partners, policymakers and other relevant stakeholders to promote best practice in this area.</p>
                  <p>RB has a Global Maternity Policy that supports creating a conducive environment and includes 16 weeks of paid leave and can be supplemented with up to 36 weeks of unpaid leave, allowing for a full year of employment protection whilst home with a new child. Those on maternity leave are supported by mentoring and other career support, including flexible working arrangements. Dedicated facilities are also available to support breastfeeding mothers in the workplace.</p>
                  <p>Where breastfeeding is not feasible or needs to be supplemented, we pledge, as a leader in science-based infant and young child nutrition, to continue our scientific and medical research to provide the highest quality BMS, so that children can achieve their full potential.</p>
                  <p>We will always comply with the local laws and our own RB policies and standards, ensuring we operate to the highest possible standard. We recognise our critical role in ensuring that Infant Formulas, Delivery Products, Follow-On Formulas and information concerning their use, are marketed responsibly. In situations where breastfeeding is not possible, we advocate the safe use and preparation of all formulas by promoting a hygienic environment and the use of safe water supplies.</p>

                  <p><em>Important Notice: Breastfeeding is best for babies. The decision to discontinue breastfeeding may be difficult to reverse and the introduction of partial bottle-feeding may reduce breast milk supply. The financial benefits of breastfeeding should be considered before bottle-feeding is initiated. Failure to follow preparation instructions carefully may be harmful to your baby's health.</em></p>
                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">Safety, quality &amp; responsibility</div>
              <div className="accordion__content">
                <div className="rich-text">
                  
                  <p>Our policies and procedures reflect the additional responsibility associated with the provision of infant nutrition, and as such, safety and quality will always be a top priority.</p>
                  <p>Parents put their trust in us and the innovative products we provide for the healthy growth and development of their children. RB takes this responsibility very seriously.</p>
                  <p>We always ensure that our products are formulated to the most stringent nutritional standards and comply with all national laws as well as international standards (such as Codex Alimentarius) related to the composition, safety, quality and manufacturing of infant nutrition products. RB is particularly proud of our uncompromising commitment to product quality and consumer safety.</p>
                  <p>RB is committed to being a key industry player in responsible marketing standards, by collaborating with governments and supporting countries, as they translate the recommendations of the WHO Code and relevant WHA resolutions into national law. RB has established responsible marketing principles, which guide all of our marketing practices and to which all employees must adhere. This ensures we are always acting in a way that is responsible and transparent to consumers, while also providing them with accurate information to make informed nutritional decisions. RB will extend these principles to take account of the unique responsibilities to market BMS products appropriately. We will ensure all our employees are trained, and that our performance is regularly monitored, reviewed and our quality standards are updated accordingly. We will work continually to ensure compliance in all of the countries where we operate.</p>

                  <p><em>Important Notice: Breastfeeding is best for babies. The decision to discontinue breastfeeding may be difficult to reverse and the introduction of partial bottle-feeding may reduce breast milk supply. The financial benefits of breastfeeding should be considered before bottle-feeding is initiated. Failure to follow preparation instructions carefully may be harmful to your baby's health.</em></p>
                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">Information &amp; education</div>
              <div className="accordion__content">
                <div className="rich-text">
                  
                  <p>Our Pledge will be communicated to all our employees involved in the distribution, marketing and sales of BMS, with appropriate training provided to support the implementation of related policies and procedures.</p>
                  <p>We will work with retail customers and distributors acting on behalf of RB (MJN) involved in bringing our products to market to establish awareness and emphasise the importance of abiding by relevant laws, our BMS Marketing Policy and this Pledge.</p>
                  <p>Access to accurate and scientifically validated nutrition information is critical for healthcare professionals to provide the best nutritional advice, and to empower mothers to make informed nutrition decisions. The critical and specialised nature of our infant and child nutrition products requires that we work closely with the scientific and medical communities. Therefore, in Lower-Risk countries, distribution of materials on Infant Formula will follow local legislation, and in Higher-Risk countries, materials on Infant Formula and Follow-on Formula, will only be distributed by health care facilities, and given or shown to mothers by healthcare professionals.</p>
                  <p>We believe that informing healthcare professionals on the latest innovations in infant and young child nutrition – including the provision of products for professional evaluation where this is permitted – enables them to advise parents on making informed choices about the most suitable nutritional options for specific circumstances.</p>
                  <p>RB pledges that we will not provide financial or material incentives to healthcare professionals to promote Infant Formula or Follow-On Formula.</p>

                  <p><em>Important Notice: Breastfeeding is best for babies. The decision to discontinue breastfeeding may be difficult to reverse and the introduction of partial bottle-feeding may reduce breast milk supply. The financial benefits of breastfeeding should be considered before bottle-feeding is initiated. Failure to follow preparation instructions carefully may be harmful to your baby's health.</em></p>
                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">Emergency &amp; disaster relief</div>
              <div className="accordion__content">
                <div className="rich-text">
                  
                  <p>RB always seeks to play its role as a good corporate citizen in supporting efforts to manage and provide emergency and disaster relief.</p>
                  <p>We pledge, in Lower-Risk countries, the provision of Infant Formula will follow local legislation, and in Higher-Risk countries, the provision of Infant Formula and Follow-on Formula, will only be provided following the specific written request of a recognised government agency. We will not distribute products directly to impacted mothers and infants. We will work closely with the government or agency to ensure any products provided to infants are done so appropriately, taking into account safe and hygienic preparation and distribution. We will always ensure that, such products are clearly labelled as a donation and that they are not intended for commercial use or distribution.</p>

                  <p><em>Important Notice: Breastfeeding is best for babies. The decision to discontinue breastfeeding may be difficult to reverse and the introduction of partial bottle-feeding may reduce breast milk supply. The financial benefits of breastfeeding should be considered before bottle-feeding is initiated. Failure to follow preparation instructions carefully may be harmful to your baby's health.</em></p>
                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">Trust, continuous improvement &amp; transparency</div>
              <div className="accordion__content">
                <div className="rich-text">
                  
                  <p>Our Infant and Child Nutrition Pledge is built on transparency and trust.</p>
                  <p>RB engages in knowledge sharing with stakeholders, seeking balanced and evidence-based dialogue with the aim of advancing health and well being of infants, children and mothers around the world.</p>
                  <p>We will be transparent in our positions and practices regarding support of breastfeeding and BMS marketing practices and will validate our commitments through independent internal and external verifications.</p>
                  <p>We will vigorously investigate any allegations of RB breaching national laws or for failing to comply with this Pledge and our policies. These will be made public and will include details of corrective actions and timeframes for implementation.</p>
                  <p>In addition to the new BMS Marketing Policy, RB will be introducing appropriate governance and management systems in 2018 to ensure that all our employees, distributors and others acting under RB's authorisation fully understand the importance of encouraging breastfeeding and to ensure compliance with the BMS Marketing Policy and the Pledge.</p>
                  <p>We will work constructively with all stakeholders including policy makers and healthcare professionals to promote evidence-based, well informed policies that help families, infants and children receive the nutrition that is best for them.</p>

                  <p><em>Important Notice: Breastfeeding is best for babies. The decision to discontinue breastfeeding may be difficult to reverse and the introduction of partial bottle-feeding may reduce breast milk supply. The financial benefits of breastfeeding should be considered before bottle-feeding is initiated. Failure to follow preparation instructions carefully may be harmful to your baby's health.</em></p>
                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">Definitions</div>
              <div className="accordion__content">
                <div className="rich-text">
                  
                  <p><strong>Breast-Milk Substitutes (BMS):</strong></p>
                  <p>means any food, being marketed or otherwise presented as a partial or total replacement for breast-milk, for infants up to 12 months, whether or not suitable for that purpose.</p>
                  <p><strong>Complementary Food:</strong></p>
                  <p>any food whether manufactured or locally prepared, suitable as a complement to breast-milk, Infant Formula, or Follow- On Formula, when either become insufficient to satisfy the nutritional requirements of the infant.</p>
                  <p><strong>Delivery Products:</strong></p>
                  <p>is understood to include items such as bottles and teats.</p>
                  <p><strong>Follow-On Formula:</strong></p>
                  <p>is formula for older infants, marketed as suitable to satisfy the nutritional requirements of infants from six months up to twelve months of age alongside the introduction of appropriate Complementary Food. Food for Special Medical Purposes ('FSMPs') intended for infants from six months of age up to 12 months of age, are specifically excluded.</p>
                  <p><strong>Higher Risk Countries:</strong></p>
                  <p>Those countries that meet either of the following criteria, according to FTSE4Good:</p>
                  <ul>
                    <li>more than 10 per 1000 (under five years of age) mortality rate, or</li>
                    <li>more than 2% acute malnutrition (moderate and severe wasting) in children under five years of age.</li>
                  </ul>
                  <p><strong>Infant:</strong> means a person from birth to twelve months of age.</p>
                  <p><strong>Infant Formula:</strong></p>
                  <p>A Breast-Milk Substitute formulated industrially in accordance with applicable Codex Alimentarius Standards, to satisfy the normal nutritional requirements of infants up to between four and six months of age, and adapted to their physiological characteristics. Food for Special Medical Purposes ('FSMPs') intended for infants from birth up to six months of age, are specifically excluded.</p>
                  <p><strong>Marketing:</strong></p>
                  <p>Means information concerning the proper use of any in-scope products, all forms of product promotion, distribution, selling, advertising, product public relations and information services.</p>
                  <p><strong>WHO Code:</strong></p>
                  <p>World Health Organisation International Code of Marketing of Breast-Milk Substitutes, of 1981.</p>

                  <p><em>Important Notice: Breastfeeding is best for babies. The decision to discontinue breastfeeding may be difficult to reverse and the introduction of partial bottle-feeding may reduce breast milk supply. The financial benefits of breastfeeding should be considered before bottle-feeding is initiated. Failure to follow preparation instructions carefully may be harmful to your baby's health.</em></p>
                </div>
              </div>
            </div>

          </section>

        </main>
      </Layout>
    )
  }
}

export default AccordionListing